import React from 'react';
import './skills.css';
function skills(props) {
	const Skills = {
		frontEnd: [
			{
				name: 'html',
				icon: './skills/html.png'
			},
			{
				name: 'css',
				icon: './skills/css.png'
			},
			{
				name: 'javascript',
				icon: './skills/javascript.png'
			},
			{
				name: 'react',
				icon: './skills/react.png'
			},
			{
				name: 'angular',
				icon: './skills/angular.png'
			}
		],
		backEnd: [
			{
				name: 'nodejs',
				icon: './skills/nodejs.png'
			},
			{
				name: 'express',
				icon: './skills/express.png'
			},
			{
				name: 'mongodb',
				icon: './skills/mongodb.png'
			},
			{
				name: 'mysql',
				icon: './skills/mysql.png'
			}
		],
		design: [
			{
				name: 'Illustrator',
				icon: './skills/Illustrator.png'
			},
			{
				name: 'Sketch',
				icon: './skills/Sketch.png'
			},
			{
				name: 'Adobe XD',
				icon: './skills/edobexd.png'
			}
		],
		Languages: [
			{
				name: 'java',
				icon: './skills/java.png'
			},
			{
				name: 'python',
				icon: './skills/python.png'
			},
			{
				name: 'php',
				icon: './skills/php.png'
			},
			{
				name: 'typescript',
				icon: './skills/typescript.png'
			}
		]
	};
	return (
		<div className='skills'>
			<h1>Skills</h1>
			<div className='sk_list'>
				<div className='sk_left'>
					<div className='sk_row'>
						<h6>Front-End</h6>
						<div className='sk_items'>
							{Skills.frontEnd.map((skill, i) => (
								<div className='sk-item' key={i}>
									<p>{skill.name}</p>
									<img src={skill.icon} alt={skill.name} />
								</div>
							))}
						</div>
					</div>
					<div className='sk_row'>
						<h6>Back-End</h6>
						<div className='sk_items'>
							{Skills.backEnd.map((skill, i) => (
								<div className='sk-item' key={i}>
									<p>{skill.name}</p>
									<img src={skill.icon} alt={skill.name} />
								</div>
							))}
						</div>
					</div>
					<div className='sk_row'>
						<h6>Design</h6>
						<div className='sk_items'>
							{Skills.design.map((skill, i) => (
								<div className='sk-item' key={i}>
									<p>{skill.name}</p>
									<img src={skill.icon} alt={skill.name} />
								</div>
							))}
						</div>
					</div>
				</div>

				<div className='sk_right'>
					<h6>Languages</h6>
					{Skills.Languages.map((Language, i) => (
						<div className='sk_row' key={i}>
							<div className='sk-item'>
								<p>{Language.name}</p>
								<img src={Language.icon} alt={Language.name} />
							</div>
						</div>
					))}
				</div>
			</div>
			<button onClick={() => props.step(4)}>
				<i className='fa fa-envelope' id='ic_right'></i> Contact Me
				<i id='icon' className={'fa fa-long-arrow-right'}></i>
			</button>
		</div>
	);
}

export default skills;
